<template>
  <div class="container col-lg-6 col-md-12 col-sm-12 my-5">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="card">
      <div class="card-header">
        <h1 class="text-center">Registro</h1>
      </div>
      <div class="card-body">
        <b-alert
          :show="dismissCountDown"
          variant="danger"
          @dismiss-count-down="countDownChanged"
        >
          <strong>Error. {{ error }},</strong> ocultando en
          {{ dismissCountDown }} segundos...
        </b-alert>
        <b-form v-if="show">
          <!--Con DNI-->
          <div v-if="optionDni == 'T'">
            <hr />
            <b-form-group
              id="input-group-1"
              label="Número de DNI:"
              label-for="input-1"
              description="Ingrese DNI real."
              class="my-2"
            >
              <b-form-input
                :state="form.dni != null && form.dni.length == 8"
                autofocus="autofocus"
                id="input-1"
                v-model="form.dni"
                type="number"
                placeholder="Ingrese DNI"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Correo electrónico:"
              label-for="input-1"
              class="my-2"
            >
              <b-form-input
                :state="validEmail(form.usuario)"
                id="input-1"
                v-model="form.usuario"
                type="email"
                placeholder="Ingrese correo"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Contraseña:"
              description="Ingrese al menos 1 letra, al menos 1 número, al menos 1 letra mayúscula, al menos 6 caracteres, no se permite espacios."
              class="my-2"
            >
              <b-input-group>
                <b-form-input
                  :state="validContra(form.contraseña)"
                  id="input-2"
                  v-model="form.contraseña"
                  :type="seeContra"
                  placeholder="Ingrese contraseña"
                  required
                ></b-form-input>
                <b-input-group-append>
                  <b-form-checkbox
                    button
                    button-variant="tranparent"
                    v-model="seeContra"
                    value="text"
                    unchecked-value="password"
                    class="mr-n2 mb-n1"
                    ><b-icon-eye-slash v-if="seeContra == 'password'" />
                    <b-icon-eye v-else />
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Verificar contraseña:"
              label-for="input-2"
              class="my-2"
            >
              <b-input-group>
                <b-form-input
                  @keyup.enter="saveEditItem"
                  id="input-2"
                  v-model="form.verificar"
                  :type="seeContraVerify"
                  placeholder="Ingrese nuevamente la contraseña"
                  required
                ></b-form-input>
                <b-input-group-append>
                  <b-form-checkbox
                    button
                    button-variant="tranparent"
                    v-model="seeContraVerify"
                    value="text"
                    unchecked-value="password"
                    class="mr-n2 mb-n1"
                    ><b-icon-eye-slash v-if="seeContraVerify == 'password'" />
                    <b-icon-eye v-else />
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <vue-recaptcha
              class="my-3"
              @render="renderMethod"
              @expired="expiredMethod"
              @verify="handleSuccess"
              @error="handleError"
              sitekey="6Le_4HQUAAAAALi-pi9JgbV1iDv7Ix1DMnrEotxj"
            ></vue-recaptcha>
            <div class="text-center">
              <b-alert
                :show="selectButton != true"
                dismissible
                variant="danger"
              >
                <b class="mb-0"> Marque la casilla de verificación. </b>
              </b-alert>
              <b-button
                type="button"
                @click="saveEditItem"
                class="mb-3 w-100"
                style="background-color: rgb(119, 35, 45); border: none"
                :disabled="selectButton != true"
                id="popover-target"
                >Registrarse</b-button
              >
              <button
                type="button"
                @click="cambio"
                class="btn btn-danger w-100"
                style="background-color: #1d425e; border: none"
              >
                Volver
              </button>
            </div>
          </div>
          <!--Sin DNI-->
          <div v-else-if="optionDni == 'F'">
            <b-alert variant="warning" show dismissible fade>
              <b
                >Campos adicionales por problema de DNI. Ingrese los datos
                correctamente, esta información sera usada por el sistema.</b
              >
            </b-alert>
            <hr />
            <b-form-group
              label="Número de DNI:"
              description="Ingrese DNI real."
              class="my-2"
            >
              <b-form-input
                :state="form.dni != null && form.dni.length == 8"
                autofocus="autofocus"
                v-model="form.dni"
                type="number"
                placeholder="Ingrese DNI"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Fecha de nacimiento:" class="my-2">
              <b-form-input
                :state="form.date != null"
                v-model="form.date"
                type="date"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Nombres:" class="my-2">
              <b-form-input
                :state="form.name != null"
                v-model="form.name"
                type="text"
                onkeyup="javascript:this.value=this.value.toUpperCase();"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Apellido paterno:" class="my-2">
              <b-form-input
                :state="form.lastnameFather != null"
                v-model="form.lastnameFather"
                type="text"
                onkeyup="javascript:this.value=this.value.toUpperCase();"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Apellido materno:" class="my-2">
              <b-form-input
                :state="form.lastnameMother != null"
                v-model="form.lastnameMother"
                type="text"
                onkeyup="javascript:this.value=this.value.toUpperCase();"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Genero:" class="my-2">
              <b-form-select
                :state="form.gender != null"
                v-model="form.gender"
                :options="[
                  { text: 'Femenino', value: 'F' },
                  { text: 'Masculino', value: 'M' },
                ]"
              />
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Correo electrónico:"
              label-for="input-1"
              class="my-2"
            >
              <b-form-input
                :state="validEmail(form.usuario)"
                id="input-1"
                v-model="form.usuario"
                type="email"
                placeholder="Ingrese correo"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Contraseña:"
              description="Ingrese al menos 1 letra, al menos 1 número, al menos 1 letra mayúscula, al menos 6 caracteres, no se permite espacios."
              class="my-2"
            >
              <b-input-group>
                <b-form-input
                  :state="validContra(form.contraseña)"
                  id="input-2"
                  v-model="form.contraseña"
                  :type="seeContra"
                  placeholder="Ingrese contraseña"
                  required
                ></b-form-input>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    switch
                    v-model="seeContra"
                    value="text"
                    unchecked-value="password"
                    class="mr-n2 mb-n1"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Verificar contraseña:"
              label-for="input-2"
              class="my-2"
            >
              <b-input-group>
                <b-form-input
                  @keyup.enter="saveEditItem"
                  id="input-2"
                  v-model="form.verificar"
                  :type="seeContraVerify"
                  placeholder="Ingrese nuevamente la contraseña"
                  required
                ></b-form-input>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    switch
                    v-model="seeContraVerify"
                    value="text"
                    unchecked-value="password"
                    class="mr-n2 mb-n1"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <vue-recaptcha
              class="my-3"
              @render="renderMethod"
              @expired="expiredMethod"
              @verify="handleSuccess"
              @error="handleError"
              sitekey="6Le_4HQUAAAAALi-pi9JgbV1iDv7Ix1DMnrEotxj"
            ></vue-recaptcha>
            <div class="text-center">
              <b-alert
                :show="selectButton != true"
                dismissible
                variant="danger"
              >
                <b class="mb-0"> Marque la casilla de verificación. </b>
              </b-alert>
              <b-button
                type="button"
                @click="saveEditItem2"
                class="mb-3 w-100"
                style="background-color: rgb(119, 35, 45); border: none"
                :disabled="selectButton != true"
                id="popover-target"
                >Registrarse</b-button
              >
              <button
                type="button"
                @click="cambio"
                class="btn btn-danger w-100"
                style="background-color: #1d425e; border: none"
              >
                Volver
              </button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  components: { VueRecaptcha },
  data() {
    return {
      seeContra: "password",
      seeContraVerify: "password",
      optionDni: "T",
      selected: "",
      selectButton: false,
      error: "",
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      form: {
        email: "",
        food: null,
        checked: [],
        usuario: "",
      },
      show: true,
    };
  },
  methods: {
    handleError() {
      this.selectButton = false;
    },
    handleSuccess() {
      this.selectButton = true;
    },
    expiredMethod() {
      this.selectButton = false;
    },
    renderMethod() {
      this.selectButton = false;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validContra: function (contra) {
      var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{6,}$/;
      return re.test(contra);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.error = data;
      this.dismissCountDown = this.dismissSecs;
    },
    cambio() {
      this.$router.push("/");
    },
    Ingreso() {
      this.$router.push("/users");
    },
    async saveEditItem() {
      //correo
      if (!this.validEmail(this.form.usuario)) {
        this.showAlert("Correo invalido");
        return;
      }
      //contraseña
      if (!this.validContra(this.form.contraseña)) {
        this.showAlert("Contraseña invalida");
        return;
      }
      this.selected = "circular";
      //console.log(this.editedItem.status);
      await this.$axios
        .post("/signup", {
          username: this.form.usuario,
          password: this.form.contraseña,
          confirmation: this.form.verificar,
          email: this.form.correo,
          dni: this.form.dni,
          dateBirth: this.form.date,
        })
        .then((res) => {
          this.$message.success("MUY BIEN TE HAS REGISTRADO EXITOSAMENTE");
          this.cambio();
        })
        .catch((error) => {
          this.selected = "";
          this.selectButton = true;
          this.showAlert(error.response.data.content);
          if (error.response.data.content == "Dni incorrecto")
            this.optionDni = "F";
          console.log(error);
        });
    },
    async saveEditItem2() {
      //correo
      if (!this.validEmail(this.form.usuario)) {
        this.showAlert("Correo invalido");
        return;
      }
      //contraseña
      if (!this.validContra(this.form.contraseña)) {
        this.showAlert("Contraseña invalida");
        return;
      }
      this.selected = "circular";
      //console.log(this.editedItem.status);
      await this.$axios
        .post("/signup/v2", {
          username: this.form.usuario,
          password: this.form.contraseña,
          confirmation: this.form.verificar,
          email: this.form.correo,
          dni: this.form.dni,
          dateBirth: this.form.date,
          name: this.form.name,
          lastnameFather: this.form.lastnameFather,
          lastnameMother: this.form.lastnameMother,
          gender: this.form.gender,
        })
        .then((res) => {
          this.$message.success("MUY BIEN TE HAS REGISTRADO EXITOSAMENTE");
          this.cambio();
        })
        .catch((error) => {
          this.selected = "";
          this.selectButton = true;
          this.showAlert(error.response.data.content);
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.card-header {
  background-color: #1d425e;
  color: white;
}
</style>